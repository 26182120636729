import { FunctionComponent } from 'react';
import Headline from '../Headline';
import GridTable from '../GridTable';
import { FilterIcon } from '../../svgComponents';
import styled from 'styled-components';
import {
  applyFilters,
  selectors as filterSelectors,
} from '../../state/filters';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as settingsSelectors } from '../../state/settings';
import { darkTheme, lightTheme } from '../../Style/theme';

const Widget = styled.div`
  background: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacings.small} 0;
`;

const FilterContainer = styled.div`
  padding: 0 ${({ theme }) => theme.spacings.medium};
  svg {
    cursor: pointer;
  }
`;

const Spacer = styled.div`
  height: ${({ theme }) => theme.spacings.medium};
  width: 1px;
`;

const WidgetDisplay: FunctionComponent = () => {
  const dispatch = useDispatch();
  const hasFiltersApplied = useSelector(filterSelectors.getHasFiltersApplied);
  const { darkMode } = useSelector(settingsSelectors.getAllSettings);

  const theme = darkMode ? darkTheme : lightTheme;
  // @ts-ignore
  const svgColor = theme.colors.text;

  return (
    <Widget>
      <Headline svgColor={svgColor} />
      <FilterContainer>
        {hasFiltersApplied ? (
          <FilterIcon
            color={svgColor}
            onClick={() => dispatch(applyFilters(false))}
          />
        ) : (
          <Spacer />
        )}
      </FilterContainer>
      <GridTable svgColor={svgColor} />
    </Widget>
  );
};

export default WidgetDisplay;
