import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
export type Tenant = 'ALL' | string[] | undefined;
export type TenantObj = {
  tenants?: Tenant;
  subTenants?: Tenant;
};

export interface FilterState {
  tenants: TenantObj;
  hasEnabledSuccess: boolean;
  hasEnabledWarn: boolean;
  hasEnabledError: boolean;
  hasFiltersApplied: boolean;
}

const INITIAL_STATE: FilterState = {
  tenants: {},
  hasEnabledSuccess: true,
  hasEnabledWarn: true,
  hasEnabledError: true,
  hasFiltersApplied: false,
};

const { actions, reducer } = createSlice({
  name: 'filters',
  initialState: INITIAL_STATE,
  reducers: {
    setTenants: (state: FilterState, action: PayloadAction<TenantObj>) => {
      state.tenants = action.payload;
    },

    enableSucces: (state: FilterState, action: PayloadAction<boolean>) => {
      state.hasEnabledSuccess = action.payload;
    },
    enableWarn: (state: FilterState, action: PayloadAction<boolean>) => {
      state.hasEnabledWarn = action.payload;
    },
    enableError: (state: FilterState, action: PayloadAction<boolean>) => {
      state.hasEnabledError = action.payload;
    },
    applyFilters: (state: FilterState, action: PayloadAction<boolean>) => {
      state.hasFiltersApplied = action.payload;
    },
  },
});

const getFilterState = (state: any): FilterState => state.filters;

const getAllFilters = createSelector(
  getFilterState,
  (filters: FilterState) => filters
);

const getHasFiltersApplied = createSelector(
  getFilterState,
  (filters: FilterState) => filters.hasFiltersApplied
);

export const selectors = {
  getAllFilters,
  getHasFiltersApplied,
};

export const {
  setTenants,
  enableError,
  enableWarn,
  enableSucces,
  applyFilters,
} = actions;

export default reducer;
