import * as React from "react";

const SvgWarn = (props) => (
  <svg
    width={37}
    height={37}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 36 18.5 1 36 36H1Z"
      fill="#FFC107"
      stroke="#FFC107"
      strokeLinejoin="round"
    />
    <path
      d="M18.5 11.694v13.611"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      d="M19.959 31.139a1.458 1.458 0 1 1-2.917 0 1.458 1.458 0 0 1 2.917 0Z"
      fill="#fff"
    />
  </svg>
);

export default SvgWarn;
