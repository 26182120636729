import * as React from "react";

const SvgSuccessDot = (props) => (
  <svg
    width={13}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={6.5} cy={6.5} r={6.5} fill="#28A745" />
    <circle cx={6.5} cy={6.5} r={6} stroke="#2D2D2D" strokeOpacity={0.4} />
  </svg>
);

export default SvgSuccessDot;
