import * as React from 'react';

const SvgFiltericon = (props) => (
  <svg
    width={19}
    height={21}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M7.715 12.993 1.191 5.019h16.43l-6.523 7.974v5.316L7.715 20v-7.007Z'
      stroke={props.color}
      strokeWidth={2}
      strokeLinejoin='round'
    />
    <path
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap='round'
      d='M1.414 1.977 16.231 16.32'
    />
  </svg>
);

export default SvgFiltericon;
