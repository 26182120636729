import * as React from "react";

const SvgErrorDot = (props) => (
  <svg
    width={13}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={6.5} cy={6.5} r={6.5} fill="#FF2F00" />
    <circle cx={6.5} cy={6.5} r={6} stroke="#0D0D0D" strokeOpacity={0.5} />
  </svg>
);

export default SvgErrorDot;
