import * as React from "react";

const SvgSuccess = (props) => (
  <svg
    width={35}
    height={35}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={17.5} cy={17.5} r={17.5} fill="#28A745" />
    <path
      d="m9.375 18.125 5.938 5.938L27.5 10"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgSuccess;
