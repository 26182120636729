import * as React from "react";

const SvgMemo = (props) => {
  return <svg
    height="25px"
    width="25px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 508.4 508.4"
  >
    <g>
      <path
        style={{fill: "#ACD9EA"}}
        d="M464.8,386.2c8.8,0,17.2-3.6,23.6-8.8L311.2,233.8c-0.8-0.8-0.8-2-0.4-2.8c0.8-0.8,2-1.2,2.8-0.4
		l177.6,143.6c6.8-6.8,10.8-16.4,10.8-26.8v-270c0-10.4-4-19.6-10.8-26.8L255.6,241.4c-0.4,0.4-0.8,0.4-1.2,0.4s-0.8,0-1.2-0.4
		L17.2,50.6C10.4,57.4,6,67,6,77.4v270c0,10.4,4.4,19.6,11.2,26.8l177.6-143.6c0.8-0.8,2-0.4,2.8,0.4s0.4,2-0.4,2.8L20,377.4
		c6.4,5.2,14.8,8.8,23.6,8.8H464.8z"
      />
      <path
        style={{fill: "#ACD9EA"}}
        d="M464.8,38.2H44c-8.8,0-17.2,4.4-23.6,9.6v0.4l234,189.6l234-189.2v-1.2
		C482,42.2,474,38.2,464.8,38.2z"
      />
    </g>
    <path
      style={{fill:"#CDEEF9"}}
      d="M464.8,38.2h-35.2L254.4,183.4L76.4,38.2H44c-8.8,0-17.2,4.4-23.6,9.6v0.4l234,189.6l234-189.2v-1.2
	C482,42.2,474,38.2,464.8,38.2z"
    />
    <path
      style={{fill:"#FFC52F"}}
      d="M370.8,439.8l-98.4-157.2c-4.8-8-11.6-12.4-18.4-12.4l0,0c-6.8,0-13.2,4.4-18.4,12.4L138,439.8
	c-4.8,8-6,16-2.8,21.2c3.2,5.6,10,9.2,19.2,9.2h200c9.6,0,16.4-3.6,19.2-9.2C376.4,455.8,375.6,447.8,370.8,439.8z"
    />
    <path
      d="M464.8,392.2H342.4c-2.4,0-4-1.6-4-4s1.6-4,4-4h122.4c19.2,0,35.6-16.8,35.6-36.4v-270c0-20-16.4-37.6-35.6-37.6H44
	c-19.2,0-36,17.6-36,37.6v270c0,19.6,16.4,36.4,36,36.4h122c2.4,0,4,1.6,4,4s-1.6,4-4,4H44c-23.6,0-44-20.4-44-44.4v-270
	c0-24.4,20.4-45.6,44-45.6h420.8c23.6,0,43.6,20.8,43.6,45.6v270C508.4,372.2,488.8,392.2,464.8,392.2z"
    />
    <path
      d="M17.6,381c-1.2,0-2.4-0.4-3.2-1.6c-1.2-1.6-1.2-4.4,0.4-5.6L193.6,229c1.6-1.2,4.4-1.2,5.6,0.4c1.2,1.6,1.2,4.4-0.4,5.6
	L20,379.8C19.2,380.6,18.4,381,17.6,381z"
    />
    <path
      d="M491.6,381c-0.8,0-1.6-0.4-2.4-0.8L310.4,235.4c-1.6-1.2-2-4-0.4-5.6c1.2-1.6,4-2,5.6-0.4l178.8,144.8c1.6,1.2,2,4,0.4,5.6
	C494,380.6,492.8,381,491.6,381z"
    />
    <path
      d="M254.4,244.6c-0.8,0-1.6-0.4-2.4-0.8L14.8,51.8c-1.6-1.2-2-4-0.4-5.6c1.2-1.6,4-2,5.6-0.4l234.4,190l234.4-190
	c1.6-1.2,4.4-1.2,5.6,0.4s1.2,4.4-0.4,5.6l-237.2,192C256.4,244.2,255.6,244.6,254.4,244.6z"
    />
    <path
      d="M254.4,189.8c-0.8,0-1.6-0.4-2.4-0.8L70,41.8c-1.6-1.2-2-4-0.4-5.6c1.2-1.6,4-2,5.6-0.4L254.8,181l22-17.6
	c1.6-1.2,4.4-1.2,5.6,0.4c1.2,1.6,1.2,4.4-0.4,5.6L257.6,189C256.4,189.4,255.6,189.8,254.4,189.8z"
    />
    <path
      d="M310,145c-1.2,0-2.4-0.4-3.2-1.6c-1.2-1.6-1.2-4.4,0.4-5.6L434,35.4c1.6-1.2,4.4-1.2,5.6,0.4c1.2,1.6,1.2,4.4-0.4,5.6
	L312.4,143.8C311.6,144.6,310.8,145,310,145z"
    />
    <path
      d="M354.4,476.2h-200c-11.6,0-20.4-4-24.4-11.6s-3.2-17.2,2.8-27.2l97.6-157.6c6-9.6,14.4-15.2,23.6-15.2l0,0
	c8.8,0,17.2,5.2,23.2,14.8c1.2,2,0.8,4.4-1.2,5.6s-4.4,0.4-5.6-1.2c-4.4-7.2-10.4-11.2-16.4-11.2l0,0c-6,0-12,4-16.8,11.6
	l-97.6,157.6c-4.4,7.2-5.6,14-2.8,18.8s8.8,7.6,17.6,7.6h200c8.4,0,14.8-2.8,17.6-7.6c2.8-4.8,1.6-11.6-2.8-18.8l-80.8-130
	c-1.2-2-0.8-4.4,1.2-5.6s4.4-0.8,5.6,1.2l6.8,10.8l74.4,119.2c6,9.6,7.2,19.6,3.2,27.2C374.4,472.2,366,476.2,354.4,476.2z"
    />
    <path d="M252.4,402.2c-2.4,0-4-1.6-4-4v-72c0-2.4,1.6-4,4-4s4,1.6,4,4v72C256.4,400.6,254.8,402.2,252.4,402.2z" />
    <path
      d="M254,435c-1.2,0-2-0.4-2.8-1.2c-0.8-0.8-1.2-1.6-1.2-2.8c0-1.2,0.4-2,1.2-2.8c1.6-1.6,4-1.6,5.6,0c0.8,0.8,1.2,1.6,1.2,2.8
	c0,1.2-0.4,2-1.2,2.8C256,434.6,254.8,435,254,435z"
    />
  </svg>
};

export default SvgMemo;
