import { TenantJob, SubTenantJob } from './../hooks/useSocketConnection';
import { JobList } from '../state/jobs';

export type DisplayStatus = 'ERROR' | 'WARN' | 'SUCCESS';

export type DisplayJob = {
  status: DisplayStatus;
  customer: string;
  tenantId: string;
  job: string;
  intervall: string;
  lastWarning: string;
  lastSuccess: string;
  maxAge: number;
  memo: string | null
};

const parseJobDetails = (jobs: JobList): any => {
  if (!Object.keys(jobs).length) return [];

  const jobsForDisplay = Object.values(jobs).map(
    (reduxJob: TenantJob | SubTenantJob) => {
      const displayJob: DisplayJob = {
        status: 'ERROR',
        customer: '',
        tenantId: '',
        job: '',
        intervall: '-',
        lastWarning: '-',
        lastSuccess: '-',
        memo: '-',
        maxAge: 0,
      };

      displayJob.status = reduxJob.job.status as DisplayStatus;
      displayJob.job = reduxJob.job.name;
      displayJob.memo = reduxJob.job.memo

      if (reduxJob.type === 'TENANT_BM') {
        displayJob.customer = reduxJob.name;
        displayJob.tenantId = reduxJob.tenantNumber;
      }

      if (reduxJob.type === 'TENANT_SUB_BM') {
        displayJob.customer =
          `${reduxJob.firstName} ${reduxJob.lastName}` || '';
        displayJob.tenantId = reduxJob.tenantSubNumber;
      }

      if (reduxJob.job.interval) {
        const keys = Object.keys(reduxJob.job.interval);
        const filteredInterval = keys.filter(
          //@ts-ignore
          (key: string) => reduxJob.job.interval[key]
        );
        displayJob.intervall = filteredInterval.join(',');
      }

      if (reduxJob.job.lastSuccess) {
        const lastSuccess = new Date(reduxJob.job.lastSuccess);
        displayJob.lastSuccess = lastSuccess.toLocaleString();
      }

      if (reduxJob.job.lastWarning) {
        const lastWarning = new Date(reduxJob.job.lastWarning);
        displayJob.lastWarning = lastWarning.toLocaleString();
      }

      if (reduxJob.job.maxAge) {
        const date = reduxJob.job.maxAge;
        const hours = date / 1000 / 60 / 60;
        displayJob.maxAge = Math.round(hours * 100) / 100;
      }

      return displayJob;
    }
  );

  return jobsForDisplay;
};

export default parseJobDetails;

/*  const displayJob2 = {
        ...reduxJob,
        job: { ...reduxJob.job },
      };
 */
