import {
  TenantJob,
  SubTenantJob,
  TBMEmailImportData,
} from "./../hooks/useSocketConnection";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

export type JobList = Record<string, TenantJob | SubTenantJob>;

export interface JobState {
  jobList: JobList;
}

const INITIAL_STATE: JobState = {
  jobList: {},
};

const { actions, reducer } = createSlice({
  name: "jobs",
  initialState: INITIAL_STATE,
  reducers: {
    setJobList: (state: JobState, action: PayloadAction<JobList>) => {
      state.jobList = action.payload;
    },
    updateJobList: (state: JobState, action: PayloadAction<JobList>) => {
      state.jobList = { ...state.jobList, ...action.payload };
      Object.values(state.jobList)
        .filter((value) => !value.job.isActive)
        ?.forEach((item) => {
          delete state.jobList[item.job.id];
        });
    },
    deleteJobList: (state: JobState) => {
      state.jobList = {};
    },
    updateJobState: (
      state: JobState,
      action: PayloadAction<TBMEmailImportData>
    ) => {
      const id = action.payload.jobId;
      if (
        action.payload.status === "SUCCESS" &&
        (!state.jobList[id].job.lastSuccess ||
          state.jobList[id].job.lastSuccess! <= action.payload.date)
      ) {
        state.jobList[id].job.lastSuccess = action.payload.date;
      }

      if (
        action.payload.status === "WARN" &&
        (!state.jobList[id].job.lastWarning ||
          state.jobList[id].job.lastWarning! <= action.payload.date)
      ) {
        state.jobList[id].job.lastWarning = action.payload.date;
      }
      if (
        !state.jobList[id].job.lastEmail ||
        state.jobList[id].job.lastEmail! <= action.payload.date
      ) {
        state.jobList[id].job.lastEmail = action.payload.date;
      }
    },
  },
});

const getJobState = (state: any): JobState => state.jobs;

const getJobList = createSelector(
  getJobState,
  (jobs: JobState) => jobs.jobList
);

export const selectors = { getJobList };

export const {
  setJobList,
  updateJobList,
  updateJobState,
  deleteJobList
} = actions;

export default reducer;
