import { germanLang } from './../utils/languageFiles';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { englishLang, ILanguage } from '../utils/languageFiles';

export type Languages = 'de' | 'en';
export interface SettingsState {
  darkMode: boolean;
  language: Languages;
  languageFile: ILanguage;
}

const INITIAL_STATE: SettingsState = {
  darkMode: false,
  language: 'en',
  languageFile: englishLang,
};

const { actions, reducer } = createSlice({
  name: 'settings',
  initialState: INITIAL_STATE,
  reducers: {
    setAllSettings: (
      state: SettingsState,
      action: PayloadAction<SettingsState>
    ) => {
      state.darkMode = action.payload.darkMode;
      state.language = action.payload.language;
      state.languageFile = action.payload.languageFile;
    },
    setDarkMode: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
    setLanguage: (state: SettingsState, action: PayloadAction<Languages>) => {
      state.language = action.payload;
      state.languageFile = action.payload === 'de' ? germanLang : englishLang;
    },
  },
});

const getSettingsState = (state: any): SettingsState => state.settings;

const getAllSettings = createSelector(
  getSettingsState,
  (settings: SettingsState) => settings
);

const getLanguage = createSelector(
  getSettingsState,
  (settings: SettingsState) => settings.languageFile
);

export const selectors = {
  getAllSettings,
  getLanguage,
};

export const { setAllSettings, setLanguage, setDarkMode } = actions;

export default reducer;
