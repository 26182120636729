import { FunctionComponent, useEffect, useState } from 'react';
import HeadlineDisplay from './HeadlineDisplay';
import { selectors as jobSelectors } from '../../state/jobs';
import { useSelector } from 'react-redux';
import { SubTenantJob, TenantJob } from '../../hooks/useSocketConnection';
import { selectors as settingsSelecors } from '../../state/settings';

export type Counts = {
  success: number;
  warning: number;
  error: number;
};

const INITIAL_COUNTS: Counts = {
  success: 0,
  warning: 0,
  error: 0,
};

const HeadlineContainer: FunctionComponent<{ svgColor: string }> = ({
  svgColor,
}) => {
  const [date, setDate] = useState(new Date());
  const [counts, setCounts] = useState<Counts>(INITIAL_COUNTS);
  const { bitsMonitoring } = useSelector(settingsSelecors.getLanguage);

  const jobs = useSelector(jobSelectors.getJobList);

  useEffect(() => {
    let success: number = 0;
    let warning: number = 0;
    let error: number = 0;

    Object.values(jobs).forEach((job: TenantJob | SubTenantJob) => {
      if (job.job.status === 'SUCCESS') success++;
      if (job.job.status === 'WARN') warning++;
      if (job.job.status === 'ERROR') error++;
    });
    setCounts({ success, warning, error });
  }, [jobs]);

  useEffect(() => {
    let timer = setInterval(() => setDate(new Date()), 60000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  const headline = `${bitsMonitoring} - ${date.toLocaleDateString()}`;
  return (
    <HeadlineDisplay headline={headline} counts={counts} svgColor={svgColor} />
  );
};

export default HeadlineContainer;
