import * as React from 'react';

const SvgArrowDown = (props) => (
  <svg
    width={13}
    height={11}
    viewBox='0 0 12 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M5.47 10.53a.75.75 0 0 0 1.06 0l4.773-4.773a.75.75 0 1 0-1.06-1.06L6 8.939 1.757 4.697a.75.75 0 0 0-1.06 1.06L5.47 10.53ZM6.75 10V0h-1.5v10h1.5Z'
      fill={props.color}
    />
  </svg>
);

export default SvgArrowDown;
