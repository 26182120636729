export type ILanguage = {
  bitsMonitoring: string;
  customer: string;
  job: string;
  tenantId: string;
  interval: string;
  ageLastSuccess: string;
  lastWarning: string;
  lastSuccess: string;
  lastEmail: string;
  maxAge: string;
  software: string;
  status: string;
};

export const englishLang: ILanguage = {
  bitsMonitoring: 'BITS Monitoring',
  customer: 'Customer',
  job: 'Job',
  tenantId: 'Tenant Id',
  interval: 'Interval',
  ageLastSuccess: 'Age last success',
  lastWarning: 'Last warning',
  lastSuccess: 'Last success',
  lastEmail: 'Email',
  maxAge: 'Max. age ',
  software: 'Software',
  status: 'Status',
};

export const germanLang: ILanguage = {
  bitsMonitoring: 'BITS Überwachung',
  customer: 'Kunde',
  job: 'Job',
  tenantId: 'Tenant Id',
  interval: 'Intervall',
  ageLastSuccess: 'Zuletzt erfolgreich',
  lastWarning: 'Letzte Warnung',
  lastSuccess: 'Letzter Erfolg',
  lastEmail: 'Email',
  maxAge: 'Max. age',
  software: 'Software',
  status: 'Status',
};
