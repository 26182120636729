import * as React from "react";

const SvgError = (props) => (
  <svg
    width={35}
    height={35}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={17.5} cy={17.5} r={17.5} fill="#FF2F00" />
    <path
      d="m9.896 25.364 7.734-7.734m7.734-7.734L17.63 17.63m0 0L9.609 9.609l15.469 15.469"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgError;
