import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  IOClient,
  IOClientBasicEvent,
  EventSubResult,
} from "@bits_devel/socket.io-client2";
import { updateJobList, updateJobState } from "../state/jobs";
import { selectors as authSelectors, setSocketContent } from "../state/auth";
import { selectors as filterSelectors } from "../state/filters";
import config from "../config/config";

export type TBMChangedEvent = IOClientBasicEvent<
  "BM_CHANGED",
  TBMChangedData | TBMEmailImportData,
  TBMChangedJoinData
>;

export type TBMChangedJoinData = {
  tenants?: Array<string> | "ALL";
  tenantSubs?: Array<string> | "ALL";
};

export type TBMChangedData = TenantJob | SubTenantJob;

export type TenantJob = {
  type: "TENANT_BM";
  tenantNumber: string;
  name: string;
  job: {
    id: string;
    interval: TBMJobInterval;
    isActive: boolean;
    name: string;
    status: BMStatus;
    maxAge: number;
    lastSuccess: string | null;
    lastWarning: string | null;
    lastEmail: string | null;
    memo: string | null;
  };
};

export type SubTenantJob = {
  type: "TENANT_SUB_BM";
  tenantNumber: string;
  tenantSubId: string;
  tenantSubNumber: string;
  company: string | null;
  title: string | null;
  gender: Gender | null;
  firstName: string | null;
  lastName: string | null;
  job: {
    id: string;
    interval: TBMJobInterval;
    isActive: boolean;
    name: string;
    status: BMStatus;
    maxAge: number;
    lastSuccess: string | null;
    lastWarning: string | null;
    memo: string | null;
    lastEmail: string | null;
  };
};

export type TBMEmailImportData =
  | {
      type: "TENANT_SUCCESS_MAIL";
      tenantNumber: string;
      date: string;
      status: BMEmailStatus;
      jobId: string;
    }
  | {
      type: "TENANT_SUB_SUCCESS_MAIL";
      tenantNumber: string;
      tenantSubId: string;
      tenantSubNumber: string;
      date: string;
      status: BMEmailStatus;
      jobId: string;
    };

export type BMEmailStatus =
  | "NONE"
  | "SUCCESS"
  | "WARN"
  | "ERROR"
  | "UNASSOCIABLE";

export type TBMJobInterval = {
  MO: boolean;
  TU: boolean;
  WE: boolean;
  TH: boolean;
  FR: boolean;
  SA: boolean;
  SO: boolean;
};

export type Gender = {
  male: "male";
  female: "female";
  diverse: "diverse";
};

export type BMStatus = "NONE" | "SUCCESS" | "WARN" | "ERROR" | "UNASSOCIABLE";

const useSocketConnection = () => {
  const [client, setClient] = useState<IOClient<never, any[]>>();
  const dispatch = useDispatch();

  const apiKey = useSelector(authSelectors.getApiKey);
  const { tenants } = useSelector(filterSelectors.getAllFilters);
  useEffect(() => {
    client?.onConnect(() => {
      console.debug("Connection established");
    });

    client?.onError((error) => {
      console.log(error);
    });

    client?.onBasicEventData<TBMChangedEvent>(
      "PROGRESS",
      "BM_CHANGED",
      (data: TBMChangedData | TBMEmailImportData) => {
        if (data.type === "TENANT_BM" || data.type === "TENANT_SUB_BM") {
          dispatch(setSocketContent(1));
            const jobObj = {
              [data.job.id]: data,
            };
            dispatch(updateJobList(jobObj));
        }

        if (
          data.type === "TENANT_SUCCESS_MAIL" ||
          data.type === "TENANT_SUB_SUCCESS_MAIL"
        ) {
          dispatch(setSocketContent(1));
          dispatch(updateJobState(data));
        }
      }
    );
  }, [client, dispatch]);
  useEffect(() => {
    client?.socket.close();
    client?.offAny();
    setClient(
      new IOClient<never, [TBMChangedEvent]>(
        config.socketURL,
        "/bm",
        undefined,
        {
          events: [
            {
              eventName: "BM_CHANGED",
              data: {
                tenants: tenants.tenants,
                tenantSubs: tenants.subTenants,
              },
            },
          ],
          cb: (
            result: EventSubResult<[TBMChangedEvent]>,
            error: Error | undefined
          ) => {
            dispatch(setSocketContent(0));
          },
        },
        {
          path: "/micro",
          transports: ["websocket", "polling"],
          query: {
            "api-key": apiKey,
          },
        }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, tenants]);
};

export default useSocketConnection;
