import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DisplayJob } from "../../utils/parseJobDetails";
import { selectors as settingsSelectors } from "../../state/settings";
import {
  applyFilters,
  selectors as filterSelectors,
} from "../../state/filters";
import { CompareKey } from "./GridTableContainer";
import styled from "styled-components";
import {
  ErrorDot,
  SuccessDot,
  WarnDot,
  ArrowUp,
  ArrowDown,
} from "../../svgComponents";
import { Order } from "./GridTableContainer";
import SvgMemo from "../../svgComponents/Memo";

type HeaderValue = {
  key: CompareKey;
  value: string;
  ref: MutableRefObject<undefined>;
};

const StatusIndicator = (status: "ERROR" | "WARN" | "SUCCESS") => {
  if (status === "ERROR") return <ErrorDot />;
  if (status === "WARN") return <WarnDot />;
  if (status === "SUCCESS") return <SuccessDot />;
};

const MemoIndicator = (memo: string | null) => {
  if (memo !== null)
    return (
      <div className="b-tooltip">
        <SvgMemo />
        <span className="b-tooltiptext">{memo}</span>
      </div>
    );
    return ""
};

const SortDirection = (
  compareKey: CompareKey,
  key: CompareKey,
  order: Order,
  svgColor: string
) => {
  if (compareKey !== key) return <ArrowSpacer></ArrowSpacer>;
  if (order === "asc") return <ArrowDown color={svgColor} />;
  if (order === "desc") return <ArrowUp color={svgColor} />;
};

const ArrowSpacer = styled.div`
  width: 13px;
  height: 10px;
`;

const FlexHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableWrapper = styled.div`
  margin: ${({ theme }) => theme.spacings.regular} 0;
  padding: 0 ${({ theme }) => theme.spacings.medium};
  color: ${({ theme }) => theme.colors.text};

  table {
    width: 100%;
    display: grid;
    overflow: auto;
    background: ${({ theme }) => theme.colors.tableBackground};
    grid-template-columns:
      minmax(100px, 0.3fr)
      minmax(100px, 0.3fr) minmax(100px, 1fr) minmax(100px, 1fr)
      minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(
        100px,
        0.5fr
      )
      minmax(100px, 1fr);

    @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
      overflow-x: scroll;
    }
  }

  table thead,
  table tbody,
  table tr {
    display: contents;
  }

  table th {
    position: sticky;
    cursor: pointer;
    top: 0;
  }

  table th,
  table td {
    text-align: left;
    padding: ${({ theme }) => theme.spacings.small};
  }

  table th span,
  table td span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    margin-right: ${({ theme }) => theme.spacings.tiny};
  }

  table tr td {
    border-top: 1px solid ${({ theme }) => theme.colors.border};
  }

  .odd {
    background: ${({ theme }) => theme.colors.tableStripes};
  }

  .status-indicator {
    padding-left: ${({ theme }) => theme.spacings.regular};
  }

  .resize-handle {
    display: block;
    position: absolute;
    cursor: col-resize;
    width: 7px;
    right: 0;
    top: 0;
    z-index: 1;
    border-right: 2px solid transparent;
  }

  .resize-handle:hover {
    border-color: #ccc;
  }

  .resize-handle.active {
    border-color: #517ea5;
  }

  .b-tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .b-tooltip .b-tooltiptext {
    visibility: hidden;
    background-color: darkcyan;
    color: #fff;
    border-radius: 6px;
    padding: 10px;
    font-size: 1.5em;
    white-space: pre-wrap;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .b-tooltip:hover .b-tooltiptext {
    visibility: visible;
  }
`;

const ResizableTable = styled.table``;

const GridTableDisplay: FunctionComponent<{
  jobsForDisplay: DisplayJob[];
  compareKey: CompareKey;
  order: Order;
  handleSorting: (key: CompareKey) => void;
  svgColor: string;
}> = ({ jobsForDisplay, compareKey, order, handleSorting, svgColor }) => {
  const dispatch = useDispatch();

  const [tableHeight, setTableHeight] = useState<string>("auto");
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const tableElement = useRef<any>(null);

  const minCellWidth: number = 50;

  const {
    status,
    customer,
    tenantId,
    job,
    interval,
    lastSuccess,
    lastWarning,
    maxAge,
  } = useSelector(settingsSelectors.getLanguage);

  const hasFiltersApplied = useSelector(filterSelectors.getHasFiltersApplied);

  useEffect(() => {
    if (!hasFiltersApplied) tableElement.current.style.gridTemplateColumns = "";
  }, [hasFiltersApplied]);

  const headerValues: HeaderValue[] = [
    { key: 'memo', value: "", ref: useRef() },
    { key: "status", value: status, ref: useRef() },
    { key: "customer", value: customer, ref: useRef() },
    { key: "tenantId", value: tenantId, ref: useRef() },
    { key: "job", value: job, ref: useRef() },
    { key: "intervall", value: interval, ref: useRef() },
    { key: "lastSuccess", value: lastSuccess, ref: useRef() },
    { key: "lastWarning", value: lastWarning, ref: useRef() },
    { key: "maxAge", value: `${maxAge} /h`, ref: useRef() },
  ];

  const mouseMove = useCallback(
    (event: MouseEvent) => {
      if (!hasFiltersApplied) dispatch(applyFilters(true));

      const gridColumns = headerValues.map(
        (headerValue: HeaderValue, index: number) => {
          if (index === activeIndex) {
            //@ts-ignore
            const width = event.clientX - headerValue.ref.current.offsetLeft;
            if (width >= minCellWidth) {
              return `${width}px`;
            }
          }
          //@ts-ignore
          return `${headerValue.ref.current.offsetWidth}px`;
        }
      );
      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(
        " "
      )}`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeIndex, minCellWidth]
  );

  useEffect(() => {
    setTableHeight(tableElement.current.offsetHeight);
  }, [jobsForDisplay]);

  const removeListeners = useCallback(() => {
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", removeListeners);
  }, [mouseMove]);

  const mouseDown = (index: number) => setActiveIndex(index);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    }
    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseDown, mouseUp, mouseMove, removeListeners]);

  return (
    <>
      <TableWrapper>
        <ResizableTable
          ref={tableElement}
          style={{ maxHeight: "600px", overflow: "auto" }}
        >
          <thead>
            <tr>
              {headerValues.map((headerValue: HeaderValue, index: number) => (
                <th
                  key={headerValue.value}
                  //@ts-ignore
                  ref={headerValue.ref}
                  onClick={() => handleSorting(headerValue.key)}
                >
                  <FlexHeader>
                    <span>{headerValue.value}</span>
                    {SortDirection(
                      compareKey,
                      headerValue.key,
                      order,
                      svgColor
                    )}
                  </FlexHeader>
                  <div
                    style={{ height: tableHeight }}
                    onMouseDown={() => mouseDown(index)}
                    className={`resize-handle ${
                      activeIndex === index ? "active" : "idle"
                    }`}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {jobsForDisplay.map((job: DisplayJob, index: number) => {
              const odd = (index + 1) % 2 !== 0 ? "odd" : "";
              return (
                <tr key={index}>
                  <td className={`${odd} status-indicator`}>
                    {MemoIndicator(job.memo)}
                  </td>
                  <td className={`${odd}`}>
                    {StatusIndicator(job.status)}
                  </td>
                  <td className={odd}>
                    <span>{job.customer}</span>
                  </td>
                  <td className={odd}>
                    <span>{job.tenantId}</span>
                  </td>
                  <td className={odd}>
                    <span>{job.job}</span>
                  </td>
                  <td className={odd}>
                    <span>{job.intervall}</span>
                  </td>
                  <td className={odd}>
                    <span>{job.lastSuccess}</span>
                  </td>
                  <td className={odd}>
                    <span>{job.lastWarning}</span>
                  </td>
                  <td className={odd}>
                    <span>{job.maxAge}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ResizableTable>
      </TableWrapper>
    </>
  );
};

export default GridTableDisplay;
