import {
  createGlobalStyle,
  DefaultTheme,
  GlobalStyleComponent,
} from 'styled-components';

const GlobalStyle: GlobalStyleComponent<{}, DefaultTheme> = createGlobalStyle`
 
`;

export default GlobalStyle;
