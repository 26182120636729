import { FunctionComponent } from 'react';import { ThemeProvider } from 'styled-components';
import GlobalStyle from './Style/globalStyle';
import Widget from './components/Widget';
import useSocketConnection from './hooks/useSocketConnection';
import useConfig from './hooks/useConfig';
import { darkTheme, lightTheme } from './Style/theme';
import { selectors as settingsSelectors } from './state/settings';
import { useSelector } from 'react-redux';

const App: FunctionComponent<{ id: string }> = ({ id }) => {
  const { darkMode } = useSelector(settingsSelectors.getAllSettings);
  const theme = darkMode ? darkTheme : lightTheme;

  useSocketConnection();
  useConfig();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle theme={theme} />
      <Widget />
    </ThemeProvider>
  );
};

export default App;
