import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  apiKey: string;
  hasSocketContent: number;
}

const INITIAL_STATE: AuthState = {
  apiKey: "",
  hasSocketContent: 0,
};

const { actions, reducer } = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    setApiKey: (state: AuthState, action: PayloadAction<string>) => {
      state.apiKey = action.payload;
    },
    setSocketContent: (state: AuthState, action: PayloadAction<number>) => {
      state.hasSocketContent = action.payload;
    },
  },
});

const getAuthState = (state: any): AuthState => state.auth;

const getApiKey = createSelector(
  getAuthState,
  (auth: AuthState) => auth.apiKey
);
const getSocketHasContent = createSelector(
  getAuthState,
  (auth: AuthState) =>  auth.hasSocketContent
);

export const selectors = {
  getApiKey,
  getSocketHasContent,
};

export const { setApiKey, setSocketContent } = actions;

export default reducer;
