import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Error, Warn, Success, ClosedEye } from "../../svgComponents";
import { Counts } from "./HeadlineContainer";
import {
  enableError,
  enableSucces,
  enableWarn,
  selectors as filterSelectors,
} from "../../state/filters";
import { selectors as filterAuthSelectors } from "../../state/auth";
import SvgLoadingBox from "../../svgComponents/LoadingBox";

const Headline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text};
  padding: ${({ theme }) => theme.spacings.medium}
    ${({ theme }) => theme.spacings.medium} 0;
`;

const HeadlineText = styled.h1`
  overflow: auto;
  font-size: ${({ theme }) => theme.fontSizes.big};
`;

const ItemWrapper = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.big};
  font-weight: bold;
  align-items: center;
  *:nth-child(2n-1) {
    cursor: pointer;
    margin-right: ${({ theme }) => theme.spacings.small};
    margin-left: ${({ theme }) => theme.spacings.medium};
  }

  *:nth-child(2n) {
    width: 14px;
  }
`;

const HeadlineDisplay: FunctionComponent<{
  headline: string;
  counts: Counts;
  svgColor: string;
}> = ({ headline, counts, svgColor }) => {
  const dispatch = useDispatch();
  const {
    hasEnabledError,
    hasEnabledWarn,
    hasEnabledSuccess,
    hasFiltersApplied,
  } = useSelector(filterSelectors.getAllFilters);

  const  hasSocketContent = useSelector(
    filterAuthSelectors?.getSocketHasContent
  );
  

  useEffect(() => {
    if (!hasFiltersApplied) {
      dispatch(enableError(true));
      dispatch(enableWarn(true));
      dispatch(enableSucces(true));
    }
  }, [hasFiltersApplied, dispatch]);

  return (
    <Headline
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "top",
        }}
      >
        <HeadlineText>{headline}</HeadlineText>
        {hasSocketContent !== 0 ? (
          <div style={{ marginBottom: "0.5rem !important" }}>
            <SvgLoadingBox></SvgLoadingBox>
          </div>
        ):''}
      </div>
      <ItemWrapper>
        <Error onClick={() => dispatch(enableError(!hasEnabledError))} />
        {hasEnabledError ? (
          <div>{counts.error}</div>
        ) : (
          <ClosedEye color={svgColor} />
        )}
        <Warn onClick={() => dispatch(enableWarn(!hasEnabledWarn))} />
        {hasEnabledWarn ? (
          <div>{counts.warning}</div>
        ) : (
          <ClosedEye color={svgColor} />
        )}
        <Success onClick={() => dispatch(enableSucces(!hasEnabledSuccess))} />
        {hasEnabledSuccess ? (
          <div>{counts.success}</div>
        ) : (
          <ClosedEye color={svgColor} />
        )}
      </ItemWrapper>
    </Headline>
  );
};

export default HeadlineDisplay;
