import { DefaultTheme } from 'styled-components';

const decorations = {
  roundness: '5px',
};

const breakPoints = {
  small: '450px',
  medium: '900px',
  large: '1100px',
};

const spacings = {
  tiny: '3px',
  small: '6px',
  regular: '12px',
  medium: '24px',
  big: '48px',
  huge: '96px',
};
const fontSizes = {
  tiny: '10px',
  small: '13px',
  regular: '16px',
  big: '22px',
  huge: '32px',
};

export const darkTheme: DefaultTheme = {
  colors: {
    error: '#AB1D2B',
    warning: '#B87A27',
    success: '#4C917E',
    text: '#B5B5B5',
    background: '#071426',
    tableBackground: '#071426',
    boxBackground: '#003b8c',
    border: '#001B28',
    tableStripes: '#2E3238',
  },
  decorations,
  spacings,
  breakPoints,
  fontSizes,
};

export const lightTheme: DefaultTheme = {
  colors: {
    error: '#C22B3A',
    warning: '#B87A27',
    success: '#4C917E',
    text: '#121212',
    background: '#fff',
    tableBackground: '#fff',
    boxBackground: '#EEEEEE',
    border: '#ccc',
    tableStripes: '#cce5f2',
  },
  decorations,
  spacings,
  breakPoints,
  fontSizes,
};
