import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from './state/auth';
import jobReducer from './state/jobs';
import filterReducer from './state/filters';
import settingsReducer from './state/settings';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    jobs: jobReducer,
    filters: filterReducer,
    settings: settingsReducer,
  },
  middleware: [...getDefaultMiddleware()],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
