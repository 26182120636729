import { FunctionComponent, useEffect, useState } from "react";
import GridTableDisplay from "./GridTableDisplay";
import { selectors as jobSelectors } from "./../../state/jobs";
import {
  applyFilters,
  selectors as filterSelectors,
} from "./../../state/filters";
import { useDispatch, useSelector } from "react-redux";
import parseJobDetails, { DisplayJob } from "../../utils/parseJobDetails";

export type CompareKey =
  | "status"
  | "customer"
  | "tenantId"
  | "job"
  | "intervall"
  | "lastSuccess"
  | "lastWarning"
  | "maxAge"
  | "memo"
  | "-";

export type Order = "asc" | "desc" | null;

const compareValues = (key: CompareKey, order: Order = "asc") => {
  return (a: any, b: any) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    const varA = getValueForSort(a, key);
    const varB = getValueForSort(b, key);
    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

const getValueForSort = (value: any, key: CompareKey) => {
  if (key === "status") {
    let status = 0;
    switch (value[key].toUpperCase()) {
      case "SUCCESS":
        status = 2;
        break;
      case "WARN":
        status = 1;
        break;
      default:
        status = 0;
        break;
    }
    return status;
  } else {
    return typeof value[key] === "string"
      ? value[key].toUpperCase()
      : value[key];
  }
};

const GridTableContainer: FunctionComponent<{ svgColor: string }> = ({
  svgColor,
}) => {
  const dispatch = useDispatch();

  const [compareKey, setCompareKey] = useState<CompareKey>("status");
  const [order, setOrder] = useState<Order>("asc");

  const {
    hasEnabledError,
    hasEnabledWarn,
    hasEnabledSuccess,
    hasFiltersApplied,
  } = useSelector(filterSelectors.getAllFilters);

  const isDefaultSort = compareKey === "status" && order === "asc";
  const isEveryStatus = hasEnabledError && hasEnabledWarn && hasEnabledSuccess;

  const jobs = useSelector(jobSelectors.getJobList);

  const jobsForDisplay = parseJobDetails(jobs);

  jobsForDisplay.sort(compareValues(compareKey, order));

  useEffect(() => {
    if (!hasFiltersApplied && !isDefaultSort) {
      handleSorting("status");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFiltersApplied]);

  useEffect(() => {
    if (!isDefaultSort || !isEveryStatus) dispatch(applyFilters(true));
    else {
      dispatch(applyFilters(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, compareKey, isEveryStatus]);

  const filteredJobs = jobsForDisplay.filter((job: DisplayJob) => {
    if (hasEnabledError && job.status === "ERROR") return job;
    if (hasEnabledWarn && job.status === "WARN") return job;
    if (hasEnabledSuccess && job.status === "SUCCESS") return job;
    return null;
  });

  const handleSorting = (key: CompareKey) => {
    if (key !== compareKey) {
      setCompareKey(key);
      setOrder("asc");
    } else {
      if (order === "asc") setOrder("desc");
      if (order === "desc") setOrder("asc");
    }
  };

  return (
    <GridTableDisplay
      jobsForDisplay={filteredJobs}
      compareKey={compareKey}
      order={order}
      handleSorting={handleSorting}
      svgColor={svgColor}
    />
  );
};

export default GridTableContainer;
