import { TenantObj } from "./../state/filters";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { selectors as authSelectors, setApiKey } from "../state/auth";
import { setDarkMode, setLanguage } from "../state/settings";
import { setTenants } from "../state/filters";
import { deleteJobList } from "../state/jobs";

type DashboardSettings = {
  apiKey: string;
  isDarkMode: boolean;
  language: "de" | "en";
  tenants: "ALL" | string[];
  subTenants: "ALL" | string[];
  type: "dashboard";
};

type WidgetInfo = {
  type: WidgetType;
  action: WidgetAction;
  dashboardConfig: DashboardSettings;
};

export type WidgetType = "bits-dashboard";
export type WidgetAction =
  | "init"
  | "loadConfig"
  | "refresh"
  | "loadConfigFinished"
  | "refreshFinished";

const useConfig = () => {
  const dispatch = useDispatch();
  const apiKey = useSelector(authSelectors.getApiKey);

  const tenantRef = useRef<TenantObj>();
  useEffect(() => {
    window.postMessage({ type: "bits-dashboard", action: "init" });
  }, []);
  useEffect(() => {
    window.addEventListener("message", (event) => {
      const settings = event.data as WidgetInfo;
      if (settings.type !== "bits-dashboard") return;
      if (settings.action === "loadConfig") {
        const newApiKey = settings.dashboardConfig.apiKey;
        const newTenants: TenantObj = {
          tenants: settings.dashboardConfig.tenants,
          subTenants: settings.dashboardConfig.subTenants,
        };

        const isNewApiKey = apiKey !== newApiKey;
        const isNewTenant =
          JSON.stringify(tenantRef.current) !== JSON.stringify(newTenants);

        if (isNewApiKey) dispatch(setApiKey(settings.dashboardConfig.apiKey));
        dispatch(setDarkMode(settings.dashboardConfig.isDarkMode));
        dispatch(setLanguage(settings.dashboardConfig.language));

        if (isNewTenant) {
          dispatch(deleteJobList());
          dispatch(setTenants(newTenants));
          tenantRef.current = newTenants;
        }
        window.postMessage({
          type: "bits-dashboard",
          action: "loadConfigFinished",
        }); 
      }

      if (settings.action === "refresh") {
        const newTenants: TenantObj = {
          tenants: settings.dashboardConfig.tenants,
          subTenants: settings.dashboardConfig.subTenants,
        };
          dispatch(deleteJobList());
          dispatch(setTenants(newTenants));
          tenantRef.current = newTenants;
        
        window.postMessage({
          type: "bits-dashboard",
          action: "refreshFinished",
        }); 
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useConfig;
