import * as React from 'react';

const SvgArrowUp = (props) => {
  return (
    <svg
      width={13}
      height={11}
      viewBox='0 0 12 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M6.53.47a.75.75 0 0 0-1.06 0L.697 5.243a.75.75 0 1 0 1.06 1.06L6 2.061l4.243 4.242a.75.75 0 0 0 1.06-1.06L6.53.47ZM6.75 11V1h-1.5v10h1.5Z'
        fill={props.color}
      />
    </svg>
  );
};

export default SvgArrowUp;
