import * as React from 'react';

const SvgClosedEye = (props) => (
  <svg
    width={14}
    height={16}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M1 8.062c3.081-5.62 9.081-5.62 12 0-2.595 4.871-9.512 4.09-12 0Z'
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='bevel'
    />
    <path
      d='M7.515 7.644c0 .381-.097.702-.226.912-.134.218-.254.249-.299.249-.045 0-.164-.031-.299-.249-.129-.21-.225-.53-.225-.912 0-.381.096-.702.225-.912.135-.218.254-.25.3-.25.044 0 .164.032.298.25.13.21.226.53.226.912Z'
      stroke={props.color}
    />
    <path
      d='m3.487 1 7.464 14'
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='bevel'
    />
  </svg>
);

export default SvgClosedEye;
